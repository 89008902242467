/* -------------------------------------------------------------------------- */
/*                                    Badge                                   */
/* -------------------------------------------------------------------------- */

.badge {
  line-height: 0.8125rem;
}

.badge-circle {
  height: 4.4rem;
  width: 4.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}
